/* eslint-disable import/order */
import './utils/config';
import React, { lazy, Suspense, useEffect } from 'react';
import { Redirect, Router, Switch } from 'react-router-dom';
import ReactDOM from 'react-dom';
import ApolloClient, { ApolloError } from 'apollo-client';
import { ApolloConsumer, ApolloProvider } from '@apollo/react-common';

import { removeGlobalLoader } from 'cf-common/src/utils/removeGlobalLoader';
import { configureLogger, log, subscribeOnErrors } from 'cf-common/src/logger';
import 'cf-common/src/openerIssueProtect';
import {
  getInterruptedHref,
  getReason,
  InitAuthRoute,
  redirectToLoginPageRenderer,
  MainBotPageRedirect,
  redirectToMainPage,
  redirectToPermissionPage,
  redirectToLoginPageRendererFromOldLoginPath,
  redirectToSignUpPageRendererFromOldLoginPath,
  redirectToAutoLoginPageRenderer,
} from '@utils/AuthFlow';
import { ReactEventHandlerForce } from '@utils/ReactEventHeandlerForce';
import { globalHistory } from '@utils/Routing';
import { PermissionGroup } from '@utils/Permissions';
import { ModalContainer } from '@ui/Modal/ModalContainer';
import { DebugCrashingComponent } from '@components/DebugCrashingComponent';
import { NotificationContainer } from '@components/ToasterContainer';
import { ActivateDiscount } from '@components/ActivateDiscount';
import { useDeviceMetrics } from 'cf-common/src/metrics';
import { getRequestIdFromApolloError } from '@utils/GQL/utils';
import { initI18nSuspense } from './i18n';
import { Login } from '@pages/Login';
import { Logout } from '@pages/Logout';
import { Auth } from '@pages/Auth';
import { Oops } from '@pages/Oops';
import { NoMatch } from '@pages/NoMatch';
import { Token } from '@pages/Token';
import { Permissions } from '@pages/Permissions';
import { onlyShowFocusOnKeyboardNavigation } from './accessibility/FocusVisible/FocusVisible';
import { ZapierConnectPage } from '@pages/ZapierConnectPage';
import { InviteWhiteLabel } from '@pages/WhiteLabel/InviteWhiteLabel';
import { LeadsQualificationPage } from '@pages/LeadsQualification';
import client from './common/services/ApolloService';
import { watchForSchemaHashChanges } from './common/services/Reloader';
import { LoginWhiteLabel } from '@pages/WhiteLabel/LoginWhiteLabel';
import { AgencyWhiteLabel } from '@pages/WhiteLabel/AgencyWhiteLabel';
import { NoBotsWhiteLabel } from '@pages/WhiteLabel/Errors/NoBotsWhiteLabel';
import { RedirectToBot } from '@pages/RedirectToBot';
import { Resctricted } from '@pages/Restricted';
import { redirectToBot } from '@utils/WhiteLabelUtils';
import {
  SharedFlowChooseBot,
  SharedFlowPageMode,
} from '@pages/SharedFlow/SharedFlowChooseBot';
import { DeepLinksPage } from '@pages/DeepLinks';
import { IntegrationZapierConnectPage } from '@pages/IntegrationZapierConnectPage';
import { IntegrationCalendlyConnectPage } from '@pages/IntegrationCalendlyConnectPage/IntegrationCalendlyConnectPage';
import { JanisTemplate } from '@pages/JanisTemplate';
import {
  Guest,
  GenericSign,
  GoogleOAuthHandler,
  Invite,
} from '@pages/MultiSystemAuth';
import { useNewPricingCookie } from '@utils/useNewPricingCookie';
import { WindowSizeProvider } from '@utils/DOM/WindowSizeProvider';
import { PremiumPlansPageContainer } from '@pages/Plans';
import { PREMIUM_BILLING_PATH } from '@pages/Billing/PremiumBillingPage/constants';
import { PremiumBillingPageRedirect } from '@pages/Billing';
import { EmailVerify } from '@components/EmailVerify';
import { initRequestsMonitoringInterceptors } from 'cf-common/src/requestsList';
import { IS_DEBUG, IS_PRODUCTION_BUILD } from 'cf-common/src/environment';
import { onBeforeBotPageRender } from '@utils/onBeforeBotPageRender';
import { collectInitialWebAppParams } from '@utils/initialWebAppParams';
import { KommoConnectPage } from '@pages/KommoConnect/KommoConnect';
import { HubSpotConnectPage } from '@pages/HubSpotConnect/HubSpotConnect';
import { ShopifyChooseBot } from '@pages/ShopifyChooseBot/ShopifyChooseBot';
import { ShopifyChooseBotError } from '@pages/ShopifyChooseBot/ShopifyChooseBotError';
import { ShopifyInstall } from '@pages/ShopifyChooseBot/ShopifyInstall';
import { collectSwitchFromAwesomePanelFlag } from '@utils/switchFromAwesomePanel';
import { AuthByAwesomePanel } from '@pages/Auth/AuthByAwesomePanel';

/**
 * сохраняет query params начальной загрузки странице
 */
const initialWebAppParams = collectInitialWebAppParams();

/**
 * Сохраняю ФЛАГ свитча из нового продукта
 */
collectSwitchFromAwesomePanelFlag(initialWebAppParams);

if (IS_DEBUG && IS_PRODUCTION_BUILD) {
  initRequestsMonitoringInterceptors();
}

// Include analytics script only on FE side in develop mode
if (process.env.NODE_ENV === 'development' && typeof window !== 'undefined')
  import(
    /* webpackMode: "eager" */
    'cf-analytics/src/analytics/core'
  );

const IntegrationWizard = lazy(
  () => import('@pages/IntegrationWizard/IntegrationWizard'),
);

/**
 * Basic concepts about routing
 *
 * Each route in the app has to be under InitAuthRoute which is responsible for checking auth,
 * availability of backend, network connection and user permissions at fb.
 *
 * Each route may have different options. e.g. some must be accessed only with auth and so on.
 *
 * General idea is to have special module (InitAuthRoute) for app initialization.
 *
 * There are 2 ways to authorize the user
 * - via facebook redirects
 * - via facebook js SDK
 *
 * There are special service components for these purpose.
 *
 * Login - parse, pass, handle GET parameters. Contains different modes such as autologin, login via SDK and so on.
 * Logout - remove all personal data
 * Auth - for authorization with facebook redirects
 * Oops - to show when app is broken (500)
 * Invite - show info about invite, handle joining the bot and different auth statuses
 *
 * As we have global injected loader in our html doc we have to manage it. It can be done in beforeRenderRoute
 * by calling removeGlobalLoader function or directly in page component.
 *
 * If we interrupt user's flow then after e.g. successful login we have to bring him back to the route he desired before.
 * In our routing it is called interruptedHref
 */

const i18nSuspenseResource = initI18nSuspense();
onlyShowFocusOnKeyboardNavigation();
watchForSchemaHashChanges();
configureLogger({ title: 'Log from dashboard' });
subscribeOnErrors();

interface TopLevelErrorBoundaryState {
  hasError: boolean;
}

class TopLevelErrorBoundary extends React.Component<
  {},
  TopLevelErrorBoundaryState
> {
  static getDerivedStateFromError() {
    return { hasError: true };
  }

  state = {
    hasError: false,
  };

  componentDidCatch(error: Error) {
    log.error({
      error,
      msg: 'App crashed',
      data: {
        label: 'app_crash',
        requestId: getRequestIdFromApolloError(error as ApolloError),
      },
    });
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <ApolloProvider client={client}>
          <Router history={globalHistory}>
            <Oops
              reason="App crashed"
              errorText="Error Boundaries in the root"
            />
          </Router>
        </ApolloProvider>
      );
    }

    return children;
  }
}

const AppRouter = ({ client }: { client: ApolloClient<any> }) => {
  useDeviceMetrics();
  useNewPricingCookie();
  i18nSuspenseResource.read();

  useEffect(() => {
    window.history.pushState = new Proxy(window.history.pushState, {
      apply: (target: any, thisArg: any, argArray?: any) => {
        return target.apply(thisArg, argArray);
      },
    });
  }, []);

  // TODO: remove globalHistory using after remove full Angular (see inner)
  return (
    <Router history={globalHistory}>
      <Switch>
        <InitAuthRoute
          path="/"
          exact
          onAuthNeededRenderer={redirectToLoginPageRenderer}
          onPermissionNeededRenderer={redirectToPermissionPage}
          render={(props) => {
            // Redirect old paths with hash to new without hash
            const { hash } = props.location;
            if (
              hash &&
              (hash.indexOf('/bot/') === 1 || hash.indexOf('/bots/') === 1)
            ) {
              removeGlobalLoader();
              return <Redirect to={hash.slice(2)} />;
            }
            return <Redirect to="/redirect-to-bot" />;
          }}
        />
        <InitAuthRoute
          onAuthNeededRenderer={redirectToLoginPageRenderer}
          beforeRenderRoute={removeGlobalLoader}
          path="/email_verify/:verification_token"
          render={(props) => <EmailVerify client={client} {...props} />}
        />
        <InitAuthRoute
          onAuthNeededRenderer={redirectToLoginPageRenderer}
          beforeRenderRoute={removeGlobalLoader}
          path="/activate_discount"
          render={() => <ActivateDiscount />}
        />
        <InitAuthRoute
          onAuthNeededRenderer={redirectToLoginPageRenderer}
          beforeRenderRoute={onBeforeBotPageRender}
          onPermissionNeededRenderer={redirectToPermissionPage}
          path={['/bot/:botId', '/bots']}
          render={(props) => <MainBotPageRedirect {...props} client={client} />}
        />
        <InitAuthRoute
          path="/go/:mode"
          beforeRenderRoute={removeGlobalLoader}
          onAuthNeededRenderer={redirectToLoginPageRenderer}
          onPermissionNeededRenderer={redirectToPermissionPage}
          render={() => <DeepLinksPage />}
          exact
        />
        <InitAuthRoute
          path="/kommo/connect"
          onAuthNeededRenderer={redirectToLoginPageRenderer}
          onPermissionNeededRenderer={redirectToPermissionPage}
          render={() => <KommoConnectPage />}
          exact
        />
        <InitAuthRoute
          path="/hubspot/connect"
          onAuthNeededRenderer={redirectToLoginPageRenderer}
          onPermissionNeededRenderer={redirectToPermissionPage}
          render={() => <HubSpotConnectPage />}
          exact
        />
        <InitAuthRoute
          path="/flow/:flowId/choose-bot/template/:platform?"
          beforeRenderRoute={removeGlobalLoader}
          onAuthNeededRenderer={redirectToAutoLoginPageRenderer}
          onPermissionNeededRenderer={redirectToPermissionPage}
          render={() => <SharedFlowChooseBot mode={SharedFlowPageMode.flow} />}
          exact
        />
        <InitAuthRoute
          path="/flowGroup/:flowGroupId/choose-bot/template/:platform?"
          beforeRenderRoute={removeGlobalLoader}
          onAuthNeededRenderer={redirectToAutoLoginPageRenderer}
          onPermissionNeededRenderer={redirectToPermissionPage}
          render={() => (
            <SharedFlowChooseBot mode={SharedFlowPageMode.flowGroup} />
          )}
          exact
        />
        <InitAuthRoute
          path="/flow/:flowId/choose-bot/:platform?"
          beforeRenderRoute={removeGlobalLoader}
          onAuthNeededRenderer={redirectToLoginPageRenderer}
          onPermissionNeededRenderer={redirectToPermissionPage}
          render={() => <SharedFlowChooseBot mode={SharedFlowPageMode.flow} />}
          exact
        />
        <InitAuthRoute
          path="/flowGroup/:flowGroupId/choose-bot/:platform?"
          beforeRenderRoute={removeGlobalLoader}
          onAuthNeededRenderer={redirectToLoginPageRenderer}
          onPermissionNeededRenderer={redirectToPermissionPage}
          render={() => (
            <SharedFlowChooseBot mode={SharedFlowPageMode.flowGroup} />
          )}
          exact
        />
        <InitAuthRoute
          path="/premium-plans"
          onAuthNeededRenderer={redirectToLoginPageRenderer}
          onPermissionNeededRenderer={redirectToPermissionPage}
          render={() => <PremiumPlansPageContainer />}
          exact
        />
        <InitAuthRoute
          path={PREMIUM_BILLING_PATH}
          onAuthNeededRenderer={redirectToLoginPageRenderer}
          onPermissionNeededRenderer={redirectToPermissionPage}
          render={() => <PremiumBillingPageRedirect />}
          exact
        />
        <InitAuthRoute
          onAuthorizedAccessWhereUserMustBeUnauthorizedRenderer={
            redirectToMainPage
          }
          path="/guest"
          needSkipInitQuery
          render={(props) => (
            <Guest
              {...props}
              interruptedHref={getInterruptedHref(props)}
              reason={getReason(props)}
              client={client}
            />
          )}
          exact
        />
        <InitAuthRoute
          onAuthorizedAccessWhereUserMustBeUnauthorizedRenderer={
            redirectToMainPage
          }
          path="/sign-up"
          needSkipInitQuery
          render={(props) => (
            <GenericSign
              {...props}
              type="sign-up"
              interruptedHref={getInterruptedHref(props)}
              reason={getReason(props)}
            />
          )}
          exact
        />
        <InitAuthRoute
          onAuthorizedAccessWhereUserMustBeUnauthorizedRenderer={
            redirectToMainPage
          }
          path="/sign-in"
          needSkipInitQuery
          render={(props) => (
            <GenericSign
              {...props}
              type="sign-in"
              interruptedHref={getInterruptedHref(props)}
              reason={getReason(props)}
            />
          )}
          exact
        />
        <InitAuthRoute
          onAuthorizedAccessWhereUserMustBeUnauthorizedRenderer={
            redirectToMainPage
          }
          path="/login"
          render={redirectToLoginPageRendererFromOldLoginPath}
          exact
        />
        <InitAuthRoute
          onAuthorizedAccessWhereUserMustBeUnauthorizedRenderer={
            redirectToMainPage
          }
          path="/fb-autologin"
          render={(props) => (
            <Login
              {...props}
              interruptedHref={getInterruptedHref(props)}
              reason={getReason(props)}
              isAutoLogin
              client={client}
            />
          )}
          exact
        />
        {/* redirect from landing */}
        <InitAuthRoute
          onAuthorizedAccessWhereUserMustBeUnauthorizedRenderer={
            redirectToMainPage
          }
          path="/autologin"
          render={redirectToLoginPageRendererFromOldLoginPath}
          exact
        />
        <InitAuthRoute
          onAuthorizedAccessWhereUserMustBeUnauthorizedRenderer={
            redirectToMainPage
          }
          path="/connect-facebook-account"
          render={redirectToSignUpPageRendererFromOldLoginPath}
          exact
        />
        <InitAuthRoute
          path="/autopermissionsrequest"
          render={(props) => {
            return (
              <Login
                {...props}
                interruptedHref={getInterruptedHref(props)}
                reason={getReason(props)}
                isAutoLogin
                useFbSdkToken={false}
                client={client}
              />
            );
          }}
          exact
        />
        {/* backend has link to this route */}
        <InitAuthRoute
          path="/autotokeninvalidation"
          render={(props) => {
            return (
              <Login
                {...props}
                permissionGroup={PermissionGroup.full_and_marketing}
                interruptedHref={getInterruptedHref(props)}
                reason={getReason(props)}
                isAutoLogin
                client={client}
              />
            );
          }}
          exact
        />
        <InitAuthRoute
          onAuthNeededRenderer={redirectToLoginPageRenderer}
          path="/permissions"
          render={(props) => (
            <Permissions
              {...props}
              interruptedHref={getInterruptedHref(props)}
              reason={getReason(props)}
              client={client}
            />
          )}
          exact
        />
        <InitAuthRoute
          path="/auth/google"
          needSkipInitQuery
          render={() => <GoogleOAuthHandler />}
          exact
        />
        <InitAuthRoute
          path="/auth/panel"
          needSkipInitQuery
          render={() => <AuthByAwesomePanel />}
          exact
        />
        <InitAuthRoute
          path="/auth"
          needSkipInitQuery
          render={(props) => <Auth {...props} client={client} />}
          exact
        />
        <InitAuthRoute
          path="/invite/:token"
          render={(props) => <Invite {...props} />}
          exact
        />
        <InitAuthRoute
          path="/invite-page/:token"
          onAuthorizedAccessWhereUserMustBeUnauthorizedRenderer={redirectToBot}
          render={(props) => <InviteWhiteLabel {...props} client={client} />}
          exact
        />
        <InitAuthRoute
          path="/login-page/:login?"
          render={(props) => <LoginWhiteLabel {...props} client={client} />}
          onAuthorizedAccessWhereUserMustBeUnauthorizedRenderer={redirectToBot}
          beforeRenderRoute={removeGlobalLoader}
          exact
        />
        <InitAuthRoute
          onAuthNeededRenderer={redirectToLoginPageRenderer}
          onPermissionNeededRenderer={redirectToPermissionPage}
          path="/agency"
          render={(props) => <AgencyWhiteLabel {...props} client={client} />}
          exact
        />
        <InitAuthRoute
          onPermissionNeededRenderer={redirectToPermissionPage}
          beforeRenderRoute={removeGlobalLoader}
          path="/no-bots"
          render={(props) => <NoBotsWhiteLabel {...props} client={client} />}
          exact
        />
        <InitAuthRoute
          onAuthNeededRenderer={redirectToLoginPageRenderer}
          onPermissionNeededRenderer={redirectToPermissionPage}
          path="/redirect-to-bot/:botId?"
          render={(props) => <RedirectToBot {...props} client={client} />}
          exact
        />
        {/* auth from admin api */}
        <InitAuthRoute
          path="/token/:token"
          render={(props) => <Token {...props} client={client} />}
          exact
        />
        <InitAuthRoute
          onAuthNeededRenderer={redirectToLoginPageRenderer}
          onPermissionNeededRenderer={redirectToPermissionPage}
          beforeRenderRoute={removeGlobalLoader}
          path="/oauth/authorize"
          render={(props) => <ZapierConnectPage {...props} client={client} />}
          exact
        />
        <InitAuthRoute
          onAuthNeededRenderer={redirectToLoginPageRenderer}
          onPermissionNeededRenderer={redirectToPermissionPage}
          beforeRenderRoute={removeGlobalLoader}
          path="/leads-qualification"
          render={(props) => (
            <LeadsQualificationPage
              {...props}
              interruptedHref={getInterruptedHref(props)}
              client={client}
            />
          )}
          exact
        />
        <InitAuthRoute
          path="/logout"
          render={(props) => (
            <Logout
              {...props}
              interruptedHref={getInterruptedHref(props)}
              client={client}
            />
          )}
          exact
        />
        <InitAuthRoute
          path="/connect-new-page"
          beforeRenderRoute={removeGlobalLoader}
          render={(props) => (
            <Suspense
              fallback={
                <div>{window.i18next.t('index-JSXText--189-loading')}</div>
              }
            >
              <IntegrationWizard {...props} />
            </Suspense>
          )}
        />
        <InitAuthRoute
          onAuthNeededRenderer={redirectToLoginPageRenderer}
          onPermissionNeededRenderer={redirectToPermissionPage}
          path="/shopify/confirm"
          render={({ location }) => (
            <Redirect
              to={{
                pathname: '/oauth/shopify/choose-bot',
                search: location.search,
              }}
            />
          )}
          exact
        />
        <InitAuthRoute
          path="/oauth/shopify"
          render={() => (
            <Switch>
              <InitAuthRoute
                path="/oauth/shopify/install"
                render={(props) => <ShopifyInstall {...props} />}
              />
              <InitAuthRoute
                beforeRenderRoute={removeGlobalLoader}
                onAuthNeededRenderer={redirectToLoginPageRenderer}
                onPermissionNeededRenderer={redirectToPermissionPage}
                path="/oauth/shopify/choose-bot"
                render={() => <ShopifyChooseBot />}
              />
              <InitAuthRoute
                beforeRenderRoute={removeGlobalLoader}
                onAuthNeededRenderer={redirectToLoginPageRenderer}
                onPermissionNeededRenderer={redirectToPermissionPage}
                path="/oauth/shopify/error"
                render={() => <ShopifyChooseBotError />}
              />
            </Switch>
          )}
        />
        <InitAuthRoute
          path="/shopify_application/oauth"
          // render={() => <ShopifyOnboardingIntegration />}
          onAuthNeededRenderer={redirectToLoginPageRenderer}
          onAuthorizedAccessWhereUserMustBeUnauthorizedRenderer={
            redirectToMainPage
          }
        />
        <InitAuthRoute
          onAuthNeededRenderer={redirectToLoginPageRenderer}
          onPermissionNeededRenderer={redirectToPermissionPage}
          path="/zapier/confirm"
          render={(props) => (
            <IntegrationZapierConnectPage {...props} client={client} />
          )}
          exact
        />
        <InitAuthRoute
          onAuthNeededRenderer={redirectToLoginPageRenderer}
          onPermissionNeededRenderer={redirectToPermissionPage}
          path="/calendly/confirm"
          render={() => <IntegrationCalendlyConnectPage />}
          exact
        />
        <InitAuthRoute
          onAuthNeededRenderer={redirectToLoginPageRenderer}
          onPermissionNeededRenderer={redirectToPermissionPage}
          path="/zone"
          render={() => <Redirect to="/" />}
          exact
        />
        <InitAuthRoute
          onAuthNeededRenderer={redirectToLoginPageRenderer}
          onPermissionNeededRenderer={redirectToPermissionPage}
          path="/unzone"
          render={() => <Redirect to="/" />}
          exact
        />
        <InitAuthRoute
          onAuthNeededRenderer={redirectToLoginPageRenderer}
          onPermissionNeededRenderer={redirectToPermissionPage}
          beforeRenderRoute={removeGlobalLoader}
          path="/templates/creator/Janis"
          render={(props) => <JanisTemplate {...props} client={client} />}
          exact
        />
        <InitAuthRoute
          exact
          needSkipInitQuery
          path="/restricted-user"
          render={() => <Resctricted />}
        />
        <InitAuthRoute
          onAuthNeededRenderer={redirectToLoginPageRenderer}
          onPermissionNeededRenderer={redirectToPermissionPage}
          beforeRenderRoute={removeGlobalLoader}
          render={(props) => <NoMatch {...props} />}
        />
      </Switch>
    </Router>
  );
};

const I18nApp = () => {
  i18nSuspenseResource.read();
  return (
    <>
      <TopLevelErrorBoundary>
        <ApolloProvider client={client}>
          <WindowSizeProvider>
            <ApolloConsumer>
              {(client: ApolloClient<any>) => (
                <>
                  <DebugCrashingComponent />
                  <ReactEventHandlerForce /> <AppRouter client={client} />
                </>
              )}
            </ApolloConsumer>
            <NotificationContainer />
            <ModalContainer />
            <div id="mount-element" />
          </WindowSizeProvider>
        </ApolloProvider>
      </TopLevelErrorBoundary>
    </>
  );
};

function App() {
  return (
    <Suspense fallback="Loading...">
      <I18nApp />
    </Suspense>
  );
}

ReactDOM.render(<App />, document.getElementById('react-root'));
